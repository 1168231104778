<script>
export default {
  name: 'AppTitle',
  data() {
    return {
      pageTitle: ''
    }
  },
  watch: {
    '$route': {
      handler() {
        if (this.$route.query.type) {
          const timer = setTimeout(() => {
            this.pageTitle = this.$route.meta.pageTitle || ''
            clearTimeout(timer)
          }, 500)
        } else {
          this.pageTitle = this.$route.meta.pageTitle || ''
        }
      },
      deep: true,
      immediate: true
    }
  }
}
</script>

<template>
  <div
    v-show="pageTitle"
    class="text-h7 font-weight-black title-line"
  >
    {{ pageTitle }}
  </div>
</template>

<style scoped lang='scss'>
.title-line {
  position: relative;

  &::before {
    display: block;
    content: '';
    position: absolute;
    top: 0;
    left: -12px;
    width: 6px;
    height: 100%;
    background-color: var(--v-primary-base);
  }
}
</style>
