const themeConfig = {
  app: {
    name: '绩效管理',
    logo: require('@/assets/images/logos/25.png'),
    isDark: false,
    isRtl: false,
    routeTransition: 'scroll-x-transition', // https://vuetifyjs.com/en/styles/transitions/#api
    // ! `semi-dark` isn't available for horizontal nav menu
    skinVariant: 'semi-dark', // default, bordered, semi-dark
    contentWidth: 'full',
  },
  menu: {
    isMenuHidden: false,
    isVerticalNavMini: false,
    verticalMenuAccordion: true,
    groupChildIcon: 'mdiCheckboxBlankCircleOutline',
    horizontalNavMenuGroupOpenOnHover: true,
  },
  appBar: {
    type: 'fixed', // fixed, static, hidden
    isBlurred: true,
  },
  footer: {
    type: 'hidden', // fixed, static, hidden
  },
  themes: {
    light: {
      primary: '#9155FD',
      accent: '#0d6efd',
      secondary: '#8A8D93',
      success: '#56CA00',
      info: '#16B1FF',
      warning: '#FFB400',
      error: '#FF4C51',
    },
    dark: {
      primary: '#9155FD',
      accent: '#0d6efd',
      secondary: '#8A8D93',
      success: '#56CA00',
      info: '#16B1FF',
      warning: '#FFB400',
      error: '#FF4C51',
    },
  },
}

export default themeConfig
