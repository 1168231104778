const base = [
  {
    path: '/error-404',
    name: 'error-404',
    component: () => import('@/views/Error404.vue'),
    meta: {
      layout: 'blank',
      resource: 'Public',
    },
  },
  {
    path: '/login',
    name: 'auth-login',
    component: () => import('@/views/Login.vue'),
    meta: {
      layout: 'blank',
      resource: 'Public',
      redirectIfLoggedIn: true,
    },
  },
  {
    path: '/pages/preview/ImgPreview',
    name: 'img-preview',
    component: () => import('@/views/preview/ImgPreview.vue'),
    meta: {
      layout: 'blank',
    },
  },
  {
    path: '/dashboards/statistics',
    name: 'dashboard-statistics',
    component: () => import('@/views/dashboards/statistics/Statistics.vue'),
    meta: {
      layout: 'content',
    },
  },
  {
    path: '/calculate/Details',
    name: 'calculate-details',
    component: () => import('@/views/calculate/Details.vue'),
    meta: {
      layout: 'content',
    },
  },
]

export default base;
