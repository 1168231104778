const serviceUrlPro = 'https://staff.xiaowutube.com/api'

// const serviceUrlDev = 'http://172.16.26.217:8071' // oy

// const serviceUrlDev = 'http://172.16.26.167:8061' // wy

// const serviceUrlDev = 'http://172.16.26.147:8061' // zwx

// const serviceUrlDev = 'http://172.16.24.200:8087' // dy

// const serviceUrlDev = 'http://172.16.25.101:8087' // DD
// const serviceUrlDev = 'http://172.16.26.127:8899' // DD

const serviceUrlDev = 'http://172.16.24.200:8087' // 测试
const serviceUrlTest = 'http://172.16.24.200:8087' // 测试

const envServiceUrlMap = {
  development: serviceUrlDev,
  production: serviceUrlPro,
  buildTest: serviceUrlTest,
}
const serviceUrl = envServiceUrlMap[process.env.NODE_ENV]
const corpId = '?corpId=ding90ca1c32ca4e1c31acaaa37764f94726'
const version = '1714979414144'
const defaultBucket = 'silverdawn-hz'
export default {
  serviceUrl,
  loginEndpoint: `${serviceUrl}/sso/doLoginByTicket`,
  userInfoEndpoint: `${serviceUrl}/sso/userInfo`,
  isLoginEndpoint: `${serviceUrl}/sso/isLogin`,
  ssoAuthUrlEndpoint: `${serviceUrl}/sso/getSsoAuthUrl`,
  logoutEndpoint: `${serviceUrl}/sso/logout`,
  routeEndpoint: `${serviceUrl}/base/route`,
  menuEndpoint: `${serviceUrl}/base/menu`,
  tokenType: 'Bearer',
  storageTokenKeyName: 'accessToken',
  storageRefreshTokenKeyName: 'refreshToken',
  storageVersionKeyName: 'version',
  defaultBucket,
  version,
  corpId,
}
